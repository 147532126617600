import GLightbox from "glightbox";

const lightbox = GLightbox({
    selector: '[data-gallery]',
    touchNavigation: true,
    loop: true,
});

document.querySelector('.menu-button').addEventListener('click', function(e) {
    e.preventDefault();
    document.documentElement.classList.toggle('menu--active');
});

document.querySelector('.menu-overlay').addEventListener('click', function(e) {
    e.preventDefault();
    document.documentElement.classList.toggle('menu--active');
});

const anchorlinks = [...document.querySelectorAll('[data-scroll]')];

anchorlinks.forEach(function(obj, index) {
    obj.addEventListener('click', function(e) {
       e.preventDefault();
       var anchor = obj.getAttribute('data-scroll').replace("#", "");
       var target = document.getElementById(anchor);
       target.scrollIntoView({ behavior: 'smooth' })
       // var target_pos = el.getBoundingClientRect().top + document.body.scrollTop;

    });
});
